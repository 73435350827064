<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Teams from "../../../services/Teams";

export default {
  page: {
    title: "Current Fixtures",
    meta: [
      {
        name: "fixtures_currents",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASEURL,
      fixtures: [],
      title: "Current Fixtures",
      items: [
        {
          text: "Admin",
        },
        {
          text: "Current Fixtures",
          active: true,
        },
      ],
    };
  },
  methods: {
    getFixtures() {
      Teams.getFixtures().then((response) => {
        this.fixtures = response;
      });
    },

    // if(minutes <= 90)
    // {
    //     this.gameTime = minutes.toString()
    // }else {
    //     this.gameTime = "90" + (minutes - 90).toString()
    // }
  },

  mounted() {
    this.getFixtures();
    //console.log(this.fixtures.data.fixtures[0].score.formatted);
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="container">
      <div class="row ">
        <div class="col-12">
          <div v-for="fx in fixtures.data.fixtures.reverse()"
              :key="fx.match_id">
            <div
              class="card"
              v-if="fx.past != 'FUTURE'"
            >
              <div >
                <div class="py-3">
                  <div class="container">
                    <div class="row">
                      <div class="d-flex justify-content-between">
                        <div>
                          {{ fx.competition.competition_name }}
                          {{ fx.competition.season }}
                        </div>
                        <div>Round {{ fx.round }}</div>
                      </div>
                    </div>
                    <div
                      v-if="
                        fx.score.formatted != '' || fx.score.formatted != null
                      "
                      class="row justify-content-center"
                    >
                      {{ fx.date_formatted }}
                    </div>
                    <div class="row  align-items-center g-2">
                      <div class="col">
                        <div class="row justify-content-center">
                          <img class="w-25" :src="fx.home_team_logo" alt="" />
                        </div>
                        <div class="row fs-3 fw-bolder justify-content-center">
                          {{ fx.home_team_short }}
                        </div>
                      </div>
                      <div class="col">
                        <div
                          v-if="
                            fx.score.formatted != '' ||
                              fx.score.formatted != null
                          "
                        >
                          <div class="row fs-1  justify-content-center">
                            {{ fx.score.formatted }}
                          </div>
                        </div>
                        <div
                          class="row fs-5  justify-content-center align-items-center"
                          v-else
                        >
                          {{ fx.date_formatted }}
                        </div>
                      </div>
                      <div class="col">
                        <div class="row justify-content-center">
                          <img class="w-25" :src="fx.away_team_logo" alt="" />
                        </div>
                        <div class="row fs-3 fw-bolder justify-content-center">
                          {{ fx.away_team_short }}
                        </div>
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col ">
                        <div class="d-flex justify-content-center">
                          <router-link
                            :to="{ path: `/fixtures/current/${fx.match_id}` }"
                          >
                            <button class="btn btn-primary">
                              Details
                            </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
